<template>
  
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    ToastificationContent
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    activateUser(uid, token){
      let data = {
        uid:uid,
        token:token
      }
      this.$store.dispatch("users/postActivationUser", { context: this, data: data })
      .then(() => 
      {
          this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'User activated successfully',
                    icon: 'RefreshCwIcon',
                    variant: 'success',
                    text: "Please log into the Dialog Iot"
                  },
                })
        this.$router.push({name:'auth-login'})
      })
      .catch(error => 
      {
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Activation Failed',
                icon: 'AlertTriangleIcon',    
                text: error.response.data.detail,
                variant: 'danger'
                },
            })
        this.$router.push({name:'auth-login'})
      })
    }
  },
  mounted() {
    this.activateUser(this.$route.params.uid,this.$route.params.token)
  }  
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-device.scss";
</style>
